.container {
    padding: 0 24px;
}

.container-section {
    padding: 24px;
}

.container-charts {
    padding: 0 8px 5px 8px;;
}

.compare-data-container {
    padding: 52px 11.5px 5px 11.5px;
}

.content-container {
    padding: 0 24px 24px;
}

.content-main {
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    margin: 0;
    min-height: unset;
}

.date-picker-hours {
    width: 248px;
}

html .form-main .filter-buttons-form {
    min-width: 100px;
    width: 100px;
}
