.fieldset {
    margin-top: 10px;
    padding-bottom: 8px;
    min-height: 90px;

    [class="ant-input-group-addon"] {
        overflow: visible;

        &::before {
            content: "x";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(16px, -50%);
            z-index: 11;
        }
    }
}

.coefficients {
    padding-bottom: 16px;
    padding-right: 10px;
    max-height: 162px;
    overflow: auto;
}

.footer {
    padding: 24px 40px;
    justify-content: center;
}
@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;