.header-account-popover {
	padding-right: 5px;
	padding-top: 15px;
	width: 230px;
}
.header-account-popover .ant-popover-arrow {
	display: none;
}

.header-account-popover .ant-popover-inner {
	background: #fafafa;
}

.header-account-popover .ant-popover-inner-content {
	padding: 0;
}

.header-popover-top {
	padding: 25px 30px 0;
}

.header-popover-top h3 {
	overflow: hidden;
	text-overflow: ellipsis;
}

.header-popover-content i {
	color: #999999;
	font-size: 22px;
	margin-right: 10px;
	transition: color 0.3s ease-out;
}

.header-account-popover .iconStyle {
	color: #999999;
	margin-left: 4px;
	margin-right: 12px;
}

.header-popover-content p:hover,
.header-popover-content p:hover i,
.header-popover-content p:hover svg {
	color: #4689FF;
}

.header-logout {
	border-top: 1px solid #ededed;
	padding: 10px 30px;
	transition: background-color 0.3s ease-out !important;
}

.header-logout:hover {
	background-color: #ededed;
}

.header-popover-content p {
	cursor: pointer;
	transition: color 0.3s ease-out;
	display: flex;
}

.header-account-main {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 0 4px 0 24px;
	border-left: 1px solid #ededed;
}

.header-account {
	background: #4689FF;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	line-height: 1;
	cursor: pointer;
	text-align: center;
    color: #fff;
    font-size: 22px;
    transition: color 0.3s ease-out;
}

.header-account:hover {
	opacity: .7;
}