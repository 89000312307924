.main {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding-top: 18vh;
	background-color: #f0f2f5;
	font-size: calc(100vh * 16 / 1080);
}

.copyRight {
	font-family: "Roboto";
	color: #4d4d4d;
	font-size: 16px;
	line-height: 1.34;
}

.confirmText {
	font-size: 23px;
	line-height: 1.172;
	color: #4D4D4D;
	text-align: center;
}

.main .confirmText {
	position: absolute;
	top: 40%;
}

.main .copyRight {
	position: absolute;
	bottom: 3.5625em;
	z-index: 0;
}

.auth_container {
	border-radius: 4px;
	box-shadow: 0 3px 6px #00000029;
	width: 500px;
	font-family: "Roboto";
	background-color: #ffffff;
	position: relative;
	z-index: 1;
}

.auth_form {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.header {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 500px;
	padding: 1.85vh;
	margin: 0 auto;
	background-color: #4689FF;
	color: #ffff;
	border-radius: 4px 4px 0 0;
	font-size: 26px;
	font-weight: 500;
	letter-spacing: 0.96px;
	box-shadow: 1px 16px 26px -11px rgba(0, 0, 0, 0.28);
	-webkit-box-shadow: 1px 16px 26px -11px rgba(0, 0, 0, 0.28);
	-moz-box-shadow: 1px 16px 26px -11px rgba(0, 0, 0, 0.28);
}

.loginAvatar {
	width: 85px;
	height: 85px;
	object-position: 100% 100%;
}

.auth_form .loginAvatar {
	margin-top: 4.26vh;
	margin-bottom: 4.9vh;
}

.loginInput {
	width: 319px;
	padding:  min(1.1vh, 10px) 9.25px;
}

.forgot_btn {
	display: flex;
	justify-content: center;
	color: #4689FF;
	cursor: pointer;
	font-size: 12px;
	line-height: 0.85;
}

.auth_form .forgot_btn {
	margin: 2.7vh 0 5.4vh 0;
}

.mainButton {
	width: 320px;
}

.auth_form .mainButton {
	margin-bottom: 5.4vh;
}

.textContent {
	display: block;
	width: 333px;
	white-space: pre-wrap;
	font-size: 14px;
	line-height: 1.35;
}

.auth_form .textContent {
	margin-bottom: 63px;
}

.buttonsBlock {
	width: 304px;
}

.secondButton {
	width: 50%;
}

.vector {
	display: flex;
	justify-content: center;
	width: 85px;
	height: 85px;
	border: 2px solid #339b22;
	border-radius: 50%;
	padding: 26px 0;
}

.auth_form .vector {
	margin-top: 4.26vh;
	margin-bottom: 1.85vh;
}

.success {
	color: #339b22;
}

.auth_form .success {
	margin-bottom: 4.4vh;
}

.successContent {
	display: block;
	width: 333px;
	white-space: pre-wrap;
	text-align: center;
	font-size: 14px;
	line-height: 1.35;
}

.auth_form .successContent {
	margin-bottom: 2.9vh;
}

.subContent {
	color: #999999;
	font-size: 14px;
	line-height: 1.36;
}

.auth_form .subContent {
	margin-bottom: 4.9vh;
}

.auth_form .validation {
	margin-top: 1.6vh;
	margin-bottom: 6.8vh;
}

.auth_form .newpasHeader {
	margin-top: 6.3vh;
	margin-bottom: 2.5vh;
	font-size: 18px;
	line-height: 1.5;
}

.headerRow {
	display: flex;
	justify-content: flex-start;
	width: 320px;
}

.errorMessage {
	display: flex;
	font-size: 10px;
	line-height: 1;
}

.errorMessage p {
	margin-left: 5px;
}

.auth_form .errorMessage {
	margin-top: 0.65vh;
}

.qr-section {
	height: 132px;
	min-height: 130px;
	width: 132px;
	min-width: 130px;
}

.verify-fieldset {
	padding: 10px !important;
	height: 132px;
	width: 132px;
}

.verify_manual_entry_key {
	padding: 6px !important;
	text-align: center;
	height: 38px;
	margin-top: 94px;
	max-height: 38px;
	min-height: 38px;
	min-width: 205px;
	right: 40px;
	white-space: nowrap;
}

.verify_text{
	font-family: "Roboto";
	line-height: 0.1;
	font-weight: 500;
	font-size: 14px;
}

.verify_code_section {
	margin-top: 10px;
	margin-bottom: 0;
	display: flex; 
	flex-direction: column;
}

.code_section {
	margin-top: 10px;
}

.verify_label {
	font-size: 10px;
	color: #4689FF;
}

.verify_code_input {
	border-radius: 0 !important;
	border-bottom: 3px solid #4689FF !important;
	height: 30px;
	width: 197px;
}

.verify_code_input div {
	opacity: 1;
}

.verify_code_input span {
	border-left: none;
}

.qr_mode_text {
	text-align: left;
	white-space: nowrap;
	color: #4689FF;
	cursor: pointer;
}