.tree {
    position: relative;

    &-list {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        box-shadow: 0px 4px 6px 1px rgba(163, 163, 163, 0.3);
        border-radius: 5px;
    }

    .ant-tree-switcher {
        display: none;
    }
    .ant-tree-treenode {
        padding: 4px 8px;
        &:not(:first-child) {
            margin-left: -24px;
        }
    }
    &.ant-tree .ant-tree-node-content-wrapper:hover,
    &.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: transparent;
        cursor: default;
    }

    .finded {
        color: #4689FF;
    }
}

.multi-select-tree .ant-select-selection-item-remove {
    display: none !important;
}
.multi-select-tree .ant-select-selection-item-content {
    margin-right: 0!important;
}
@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;