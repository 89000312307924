.fieldset {
    position: relative;
    padding: 24px;
    margin-bottom: 16px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    width: 100%;

    .title {
        position: absolute;
        top: 0;
        left: 30px;
        transform: translateY(-50%);
        background-color: #fff;
        width: auto;
        padding: 0 10px;
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.03em;
        color: #4D4D4D;
        margin: 0;
    }


    .border-dashed {
        border: 1px dashed #D1D1D1;
    }

    .h-88 {
        height: 88px;
    }
}

.error-text {
    color: #ff4d4f;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: -10px;
}

.fieldset .compare-fildset {
    width: 50%;
    padding: 14px;
}

.error {
    margin-bottom: 2px;
    border: 1px solid #ff4d4f;
}

@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;