@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/fonts/icomoon.eot?x1hcnr');
  src:  url('../fonts/fonts/icomoon.eot?x1hcnr#iefix') format('embedded-opentype'),
    url('../fonts/fonts/icomoon.ttf?x1hcnr') format('truetype'),
    url('../fonts/fonts/icomoon.woff?x1hcnr') format('woff'),
    url('../fonts/fonts/icomoon.svg?x1hcnr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-AddPromotion:before {
  content: "\e944";
}
.icon-PlayLink:before {
  content: "\e921";
  color: #999;
}
.icon-Report:before {
  content: "\e943";
}
.icon-riskManagement:before {
  content: "\e942";
}
.icon-copy:before {
  content: "\e941";
}
.icon-Chat:before {
  content: "\e940";
}
.icon-Promotions:before {
  content: "\e922";
}
.icon-icon_delete:before {
  content: "\e920";
}
.icon-Interaction_Arrows:before {
  content: "\e923";
}
.icon-Dice_05 .path1:before {
  content: "\e924";
  color: rgb(255, 255, 255);
}
.icon-Dice_05 .path2:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_05 .path3:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_05 .path4:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_05 .path5:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_05 .path6:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_04 .path1:before {
  content: "\e92a";
  color: rgb(255, 255, 255);
}
.icon-Dice_04 .path2:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_04 .path3:before {
  content: "\e92c";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_04 .path4:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_04 .path5:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_03 .path1:before {
  content: "\e930";
  color: rgb(255, 255, 255);
}
.icon-Dice_03 .path2:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_03 .path3:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_03 .path4:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_02 .path1:before {
  content: "\e934";
  color: rgb(255, 255, 255);
}
.icon-Dice_02 .path2:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_02 .path3:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_01 .path1:before {
  content: "\e937";
  color: rgb(255, 255, 255);
}
.icon-Dice_01 .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_06 .path1:before {
  content: "\e939";
  color: rgb(255, 255, 255);
}
.icon-Dice_06 .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_06 .path3:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_06 .path4:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_06 .path5:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_06 .path6:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-Dice_06 .path7:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(51, 51, 51);
}
.icon-HiLo_Playing_Card .path1:before {
  content: "\e90d";
  color: rgb(255, 255, 255);
}
.icon-HiLo_Playing_Card .path2:before {
  content: "\e90e";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-HiLo_Playing_Card .path3:before {
  content: "\e918";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-HiLo_Playing_Card .path4:before {
  content: "\e919";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-HiLo_Playing_Card .path5:before {
  content: "\e91a";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-HiLo_Playing_Card .path6:before {
  content: "\e91b";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-HiLo_Playing_Card .path7:before {
  content: "\e91c";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-HiLo_Playing_Card .path8:before {
  content: "\e91d";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-HiLo_Playing_Card .path9:before {
  content: "\e91e";
  margin-left: -0.6708984375em;
  color: rgb(0, 0, 0);
}
.icon-Penalty_soccer-ball:before {
  content: "\e90c";
  color: #4d4d4d;
}
.icon-Rocketon_stats:before {
  content: "\e91f";
  color: #4d4d4d;
}
.icon-Crash_Statistics:before {
  content: "\e92f";
  color: #4d4d4d;
}
.icon-Content_Management:before {
  content: "\e900";
}
.icon-Dashboard:before {
  content: "\e901";
}
.icon-Dropdown:before {
  content: "\e902";
}
.icon-Edit:before {
  content: "\e903";
}
.icon-Exit:before {
  content: "\e904";
}
.icon-Export:before {
  content: "\e905";
}
.icon-Games:before {
  content: "\e906";
}
.icon-Logo:before {
  content: "\e907";
}
.icon-Logs:before {
  content: "\e908";
}
.icon-Management:before {
  content: "\e909";
}
.icon-Players:before {
  content: "\e90a";
}
.icon-Search:before {
  content: "\e90b";
}
.icon-View:before {
  content: "\e90f";
}
.icon-Account:before {
  content: "\e910";
}
.icon-Add_circle:before {
  content: "\e911";
}
.icon-Arrow_Left:before {
  content: "\e912";
}
.icon-Arrow_right:before {
  content: "\e913";
}
.icon-Bonus:before {
  content: "\e914";
}
.icon-Calendar:before {
  content: "\e915";
}
.icon-Collapse_Expand:before {
  content: "\e916";
}
.icon-Configs:before {
  content: "\e917";
}

@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;