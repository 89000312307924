.h-full {
	height: 100%;
}

.w-50 {
	width: 50%;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.font-bold {
	font-weight: 700;
}

.hide {
	display: none !important;
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-y-auto {
	overflow-y: auto;
}

.disabled {
	pointer-events: none;
	opacity: 0.7 !important;
	transition: none !important;
}

.w-100 {
	width: 100%;
}

.pt-10 {
	padding-top: 10px;
}

.p-24 {
	padding: 24px;
}

.p-16 {
	padding: 16px;
}

.pt-24 {
	padding-top: 24px;
}

.ml-22 {
	margin-left: 22px;
}

.mr-16 {
	margin-right: 16px;
}

.mr-10 {
	margin-right: 10px;
}

.ml-16 {
	margin-left: 16px;
}

.pl-20 {
	padding-left: 20px;
}

.pb-16 {
	padding-bottom: 16px;
}

.pb-24 {
	padding-bottom: 24px;
}

.pt-0 {
	padding-top: 0;
}

.mt-0 {
	margin-top: 0;
}

.mb-0 {
	margin-bottom: 0;
}

.mb-8 {
	margin-bottom: 8px;
}

.mt-16 {
	margin-top: 16px;
}

.mb-16 {
	margin-bottom: 16px;
}

.mt-10 {
	margin-top: 10px;
}

.mr-12 {
	margin-right: 12px;
}

.mr-40 {
	margin-right: 40px;
}

.ml-auto {
	margin-left: auto;
}

.h100 {
	height: 100%;
}

.i-flex {
	display: inline-flex;
}

.flex {
	display: flex;
}

.justify-center {
	justify-content: center;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.align-center {
	display: flex;
	align-items: center;
}

.justify-end {
	justify-content: flex-end;
}

.flex-between {
	display: flex;
	justify-content: space-between;
}

.basis-full {
	flex-basis: 100%;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.self-bottom {
	align-self: flex-end;
}

.max-w-full {
	max-width: 100%;
}

.align-bottom {
	align-items: flex-end;
}

.align-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.mr-24 {
	margin-right: 24px;
}

.mt-24 {
	margin-top: 24px;
}

.mb-24 {
	margin-bottom: 24px;
}

.mt-30 {
	margin-top: 30px;
}

.ml-8 {
	margin-left: 8px;
}

.flex-grow-1 {
	flex-grow: 1;
}

.cursor-pointer {
	cursor: pointer;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.items-end {
	align-items: flex-end;
}

.gap-16 {
	gap: 16px;
}

.text-lg {
	font-size: 1.125rem;
}

.underline {
	text-decoration-line: underline;
}
