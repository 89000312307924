.input {
    position: relative;
    width: 232px;
    padding-left: 16px;

    [data-class="edit-button"] {
        display: none;
    }
    &:hover {
        [data-class="edit-button"] {
            display: inline-block;
        }
    }

    input {
        box-shadow: none!important;
        &:read-only {
            background: transparent;
            border-color: transparent;
            padding-left: 0;
        }
    }
}

.editButtons {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    z-index: 1;

    [aria-label="check"] {
        color: #00912C;
    }
}

.uploader_import {
	text-align: center;
	min-height: 89px;
	margin-top: 16px;
}

.inbox_icon  {
	color: #4689FF;
	font-size: 24px;
	margin-top: 13px;
	margin-right: 5px;
}
@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;