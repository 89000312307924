.main {
	/* Changed default values */
	padding: 16px;

	:where(th, td) {
		border-left: rgba(0, 0, 0, 0) !important;
		border-right: rgba(0, 0, 0, 0) !important;
		color: #4d4d4d !important;
	}

	:where(tr, td, tbody, table) {
		background: rgba(0, 0, 0, 0) !important;
	}

	th::before {
		color: #4d4d4d !important;
		background-color: #e6e6e6 !important;
		font-weight: bold;
	}

	thead tr th {
		padding-top: 10px !important;
		padding-bottom: 10px !important;
	}

	table {
		margin: 10px 0px;
	}

	.nested tr {
		background: rgba(49, 136, 255, 0.1) !important;
	}

	[class~="ant-table-row-level-0"]:has(+ [class~="ant-table-expanded-row"]) {
		background: rgba(49, 136, 255, 0.2) !important;
	}
	/* */
}

.actionButton {
	color: #999999 !important;
	padding: 0 5px !important;
	transition: color 0.3s ease-out;
	cursor: pointer;

	&:hover {
		color: #4689ff !important;
	}
}

@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;