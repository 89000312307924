.dots {
    height: 4px;
    width: 4px;
    background-color: #cccccc;
    transition: background-color 0.3s ease-out;
    margin-right: 2px;
    display: inline-block;
    border-radius: 50%;
}

.dots-container {
    display: inline-block;
    cursor: pointer;
}

.dots-container:hover .dots {
    background-color: #4689FF;
}
