@import "~antd/dist/antd.less";
@import "../assets/icomoon/style.css";
@import "./_fonts.less";
@import "./_common.less";

html,
body,
#root {
	height: 100%;
	background-color: #ffffff;
}

/* ScrollBar */
::-webkit-scrollbar {
	height: 6px;
}

::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track-piece {
	background-color: transparent;
}

::-webkit-scrollbar-thumb:horizontal,
::-webkit-scrollbar-thumb:vertical {
	background: #bcbcbc;
	border-radius: 0.1875em;
}

/* ScrollBar */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	background: #000000;
	touch-action: manipulation;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
	/* Firefox */
}

tbody .ant-table-row:nth-child(odd),
tbody .ant-table-row:nth-child(odd) .ant-table-cell-fix-left,
tbody .ant-table-row:nth-child(odd) .ant-table-cell-fix-right {
	background: #f5f5f5;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	padding: 6px;
}
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > td {
	border: 1px solid #f0f0f0;
}

.ant-table-thead .ant-table-cell-fix-left,
.ant-table-thead .ant-table-cell-fix-right,
.ant-table-tbody .ant-table-cell-fix-left,
.ant-table-tbody .ant-table-cell-fix-right {
	padding: 2px 12px;
}

.disabled-view textarea:disabled,
.disabled-view input:disabled,
.readonly textarea:read-only,
.readonly input:read-only {
	background-color: #f5f5f5;
	border-color: #ccc;
	color: #4d4d4d;
	opacity: 1 !important;
}

.disabled-fields {
	.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
	.ant-input-affix-wrapper-disabled,
	.ant-input[disabled] {
		color: #4d4d4d;
		opacity: 1 !important;
	}
}

.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
	height: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
	.ant-select-selector
	.ant-select-selection-placeholder {
	line-height: 26px;
}

.ant-input-sm {
	padding: 2px 7px;
}
.ant-dropdown-open.openedDropdownIcon {
	transform: rotate(90deg);
}

#components-layout-demo-top-side-2 .logo {
	width: 120px;
	height: 31px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px 28px 16px 0;
	float: left;
}

.site-layout-background {
	background: #fff;
}

.ant-layout {
	position: relative;
	height: 100%;
	background: #f2f2f2;
}

.ant-form-item-required:before {
	position: absolute;
	right: -15px;
}

.form-main {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	gap: 14px;

	&.ant-form-vertical .ant-form-item-label {
		padding: 0 0 4px;
	}
}

.form-main .ant-form-item,
.date-picker {
	min-width: 248px;
	width: 248px;

	&.width-auto {
		width: auto;
		min-width: auto;
	}
}

.compare-data-container .form-main .ant-form-item {
	min-width: 232px;
	width: 232px;
}

.form-main .ant-form-item.full-visible {
	min-width: 340px;
}

.filter-divider {
	background-color: #e6e6e6;
	width: 1px;
	height: 40px;
	top: 28px;
	margin: 0 16px;
}

.flex.cardContainer {
	padding: 30px 23.5px 10px;
}

.flex.flex.cardContainer > *:not(:last-child) {
	margin-right: 16.5px;
}

.custom-buttons {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 100px;
}

th.text-right {
	text-align: right !important;
}

.justify-center {
	justify-content: center;
	align-items: center;
}

.filter-buttons-form .ant-form-item-control {
	display: flex;
	justify-content: flex-end;
}

.filter-buttons {
	display: flex;
}

.filter-buttons button {
	width: 100px;
}

.export-import {
	padding-bottom: 16px;
}

.languages-form-group {
	overflow: auto;
	max-height: 200px;
	margin-top: 26px;
}

.languages-form-group > div {
	margin-right: 16px;
	margin-bottom: 16px;
}

.ant-pagination-item-link {
	border: none !important;
}

.action-switch {
	width: 34px;
}

.ant-pagination-item {
	border: 1px solid transparent;
	transition: all 0.3s ease-out;
}

.ant-pagination-item-active,
.ant-pagination-item:hover {
	border: 1px solid #4689ff !important;
}

.ant-table-content {
	//overflow-x: auto;
	/* height: calc(100% - 5em); */
	scrollbar-color: #bcbcbc;
	scrollbar-width: thin;
}

.ant-tabs-tab {
	padding: 15px 20px;
	margin: 0;
}

.datePicker {
	width: 248px;
}
.App {
	box-sizing: border-box;
	width: 100%;
	height: 100%;
}

.ant-select-item-option-state {
	display: none !important;
}

.ant-input-search-icon::before {
	display: none;
}

.ant-divider-horizontal.ant-divider-with-text-left {
	font-size: 14px;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
	width: 2%;
}

.multi-dropdown {
	border-radius: 4px;
	padding: 10px 0;
	background: #ffffff;
	box-shadow: 0px 3px 6px #0000007e;
}

.multi-dropdown-item {
	width: 100%;
	padding: 8px 16px 8px;
	transition: background-color 0.3s ease-out;
}

.multi-dropdown-item .ant-checkbox-wrapper {
	width: 100%;
	height: 100%;
}

.multi-dropdown-item:hover {
	background-color: #f2f2f2;
}

.multi-dropdown-item:first-child {
	margin-top: 0;
}

.multi-dropdown .ant-checkbox + span {
	padding-right: 16px;
	padding-left: 16px;
}

.multi-dropdown-item-container {
	max-height: 300px;
	overflow-y: auto;
}

.multi-dropdowns {
	margin-bottom: 8px;
}

.export-buttons {
	border-top: 1px solid #efefef;
	padding-top: 10px;
}

.multi-dropdown-label {
	cursor: pointer;

	.anticon {
		margin-left: 8px;
	}

	svg {
		transition: transform 0.3s ease-out;
	}
}

.ant-dropdown-open {
	svg {
		transform: rotateX(180deg);
	}
}

.ant-dropdown-open .anticon-search {
	svg {
		transform: none;
	}
}

.custom-ant-tag {
	width: 100px;
	height: 22px;
	text-align: center;
	border-radius: 4px;
	border: none;

	&-small {
		width: auto;
		min-width: 34px;
		padding: 0 8px;
	}
}

.ant-tag-success {
	background-color: rgba(0, 145, 44, 0.15);
	color: #00912c;
}

.ant-tag-default {
	background-color: rgba(77, 77, 77, 0.15);
	color: #4d4d4d;
}

.ant-tag-active {
	background-color: rgba(119, 63, 190, 0.1);
	color: #773fbe;
}

.ant-tag-inactive {
	background-color: rgba(77, 77, 77, 0.1);
	color: #4d4d4d;
}

.ant-modal-title {
	display: flex;
	justify-content: center;
}

.ant-dropdown-menu-item-divider {
	min-height: 1.5px;
	max-height: 1.5px;
	padding: 0;
}

.modal-btns {
	display: flex;
	justify-content: space-between;
}

.shadow-card {
	min-width: 248px;
	height: 70px;
	border: 1px;
	-webkit-box-shadow: 0px 2px 7px 0px rgb(230 230 230);
	box-shadow: 0px 2px 7px 0px rgb(230 230 230);
	line-height: 14.06px;
	font-family: "Roboto";
	font-weight: 400;
	margin: 0;
}

.shadow-card .cardTitle {
	font-size: 12px;
	line-height: 1;
	color: #999999;
	margin-bottom: 8px;
}

.shadow-card .cardContent {
	font-size: 24px;
	line-height: 2;
	color: #4d4d4d;
}

.blackjack-array,
.keno-array,
.dice-array {
	display: flex;
	flex-direction: row;
}

.dice-small > *:not(:last-child) {
	margin-right: 6px;
}

.dice-medium > *:not(:last-child) {
	margin-right: 12px;
}

.keno-small > *:not(:last-child) {
	margin-right: 4px;
}

.keno-medium > *:not(:last-child) {
	margin-right: 8px;
}

.blackjack-small {
	& > *:not(:last-child) {
		margin-right: 16px;
	}

	flex-wrap: wrap;
	max-width: 350px;
}

.blackjack-medium > *:not(:last-child) {
	margin-right: 34px;
}

.hiloDiv {
	display: flex;
	align-items: center;
}

.hiloDivSmall > *:not(:last-child) {
	margin-right: 12px;
}

.hiloDivMedium > *:not(:last-child) {
	margin-right: 19px;
}

.vectorSmall {
	width: 5.31px;
	height: 8.46px;
	object-position: 100% 100%;
}

.vectorMedium {
	width: 10.63px;
	height: 16.92px;
	object-position: 100% 100%;
}

.ant-popover-inner-content {
	padding: 8px 15px;

	& > p {
		margin: 8px 0;
	}
}

.muted-text {
	color: #999;
}

.menu-label-full-width {
	.ant-menu-title-content {
		display: block;

		label {
			width: 100%;
		}
	}
}

.fake-link {
	cursor: pointer;
	text-decoration: underline;
	color: #4689ff;
}

.compare-filters {
	width: 33%;
}

.cms-buttons {
	width: 110px;
	height: 36px;
	margin-left: 40px;
}

.cms-button-leaderboard {
	width: 110px;
	height: 36px;
}

.percentageUp {
	color: #00912c;
	align-items: center;
}

.percentageDown {
	color: #c30909;
	align-items: center;
}

.ant-checkbox-wrapper.error-checkbox .ant-checkbox-inner,
.ant-checkbox.error-checkbox .ant-checkbox-inner,
.ant-checkbox-input.error-checkbox:focus + .ant-checkbox-inner {
	border-color: #c30909;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	overflow-wrap: anywhere;
}

.text-nowrap {
	white-space: nowrap;
}

.ant-modal-footer {
	text-align: center;
	background: transparent;
	border-top: 0;
	padding: 24px;

	.ant-btn {
		min-width: 100px;
	}
	.ant-btn-default {
		border: 0;
	}
}
.footerRight .ant-modal-footer {
	text-align: right;
}

.my-ant-input-group-addon {
	.ant-input-group-addon {
		min-width: 100px;
	}

	.ant-input {
		padding-left: 16px;
	}
}

.my-antd-tabs .ant-tabs-tab {
	min-width: 160px;
	justify-content: center;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
	padding-left: 12px;
}
.ant-upload.ant-upload-select-picture-card {
	border: 0;
	width: 100%;
	height: 100%;
}
.ant-upload-picture-card-wrapper {
	height: 100%;
}

.resultRow {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.creation-date {
	height: 48px;
	border-bottom: 1px solid #f0f0f0;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
	border: 1px solid transparent;
}

.nav-button {
	font-size: 24px;
	color: #999999;
	transition: color 0.3s ease-out;
	cursor: pointer;
}

.nav-button:hover {
	color: #4689ff;
}

/* (+) icon color */
.ant-table-row-expand-icon {
	border: 1px solid #4689ff !important;

	&::before,
	&::after {
		color: #4689ff;
	}
}

.form-main .ant-form-item-with-help .ant-form-item-explain-connected {
	position: relative;
}

.form-main .ant-form-item-explain-error {
	position: absolute;
	left: 0;
}

.ant-table-cell-fix-right {
	.ant-btn-text,
	.ant-btn-text {
		color: #999;
		&:hover,
		&:focus {
			color: #4689ff;
		}
	}
}

.ant-table-body {
	width: calc(100% + 5px);
}

@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;