.logo-container {
    position: relative;
    width: 236px;
    display: flex;
    justify-content: center;
    padding-right: 25px;

    &::after {
        content: "";
        width: 1px;
        height: 32px;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        background-color: #E1E1E1;
    }
}

.logo {
    font-size: 18px;
    line-height: 1;
    color: #4689FF;

    img {
        height: 1.22em;
        max-width: 100%;
    }
}

.current-date {
    margin-left: 16px;
    font-size: 12px;
    color: rgba(77, 77, 77, 0.7);
    font-weight: 600;
    line-height: 1;
}

@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;