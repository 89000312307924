.container {
    border: 1px dashed #CCCCCC;
    height: 450px;
    overflow: auto;
    padding: 16px;
}

.list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.upload {
    height: 104px;
}
.col {
    position: relative;
    width: 104px;
    background: #F5F5F5;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
}

.name {
    display: block;
    padding: 6px;
    font-size: 10px;
    line-height: 1.2;
    color: #777777;
	border-bottom: 1px solid #D9D9D9;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.controls {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0,0,0,.5);
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5em;
    opacity: 0;
    transition: opacity .3s;

    &:hover {
        opacity: 1;
        transition: opacity .3s;
    }

    svg {
        cursor: pointer;
    }
}

.imageWrapper {
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    padding: 20px;
}

.buttons {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}
@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;