.header-providers {
	display: flex;
	align-items: center;

	& > * {
		margin-right: 20px !important;
	}

	.rates-btn {
		min-width: 100px;
	}
}

.currency-select {
	min-width: 100px;
}

.tabChildrenContainer {
	padding: 0 44px;
}

.rateModal {
	.ant-tabs-tab {
		padding: 20px 44px;
		font-size: 16px;
	}

	&__form {
		.form-main .ml-auto .ant-form-item {
			width: auto;
			min-width: auto;
		}
		.form-main .ant-form-item {
			&.small {
				width: 100px;
				min-width: 100px;
			}
			&.medium {
				width: 200px;
				min-width: 200px;
			}

			.ant-btn {
				width: 86px;
			}
		}
	}

	&__buttons {
		display: flex;
		padding-top: 24px;

		& > .ant-btn-link {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.form-main .ant-form-item:last-child {
		margin-right: 0;
	}

	&__table {
		.ant-table-body {
			overflow-y: auto !important;
			height: 250px;
			max-height: auto !important;
		}
		.ant-table-placeholder {
			height: 250px;
		}

		&.precision {
			input {
				width: 86px;
			}
		}
	}

	.fieldset {
		margin-top: 32px;
		display: flex;
		text-align: center;
		position: relative;

		.title {
			font-size: 12px;
			text-transform: capitalize;
			letter-spacing: 0.01em;
			font-weight: 400;
		}

		&__item {
			width: 50%;
			display: flex;
			align-items: center;
			justify-content: center;

			&--input {
				input {
					margin-right: 16px;
					width: 100px;
					text-align: right;
				}
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 50%;
					bottom: 0;
					width: 1px;
					background-color: #cccccc;
				}
			}
		}
	}
}

@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;