.main{
    display: flex;
    flex-direction: row;
    color:#7C7C7C;
    font-size: 10px;
}

.main .column:not(:last-child){
    margin-right: 52px;
}

.column{
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 font-size: 10px;
 line-height: 1.172;
}

