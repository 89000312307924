.select-option {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0 10px;
	background-color: #ffffff;
}
.ant-checkbox-wrapper.select-option {
	padding: 0 10px;
}

.checkbox-select-option {
	height: 100%;
	width: 100%;
	display: flex;
	padding: 3px 7px;
	background-color: #ffffff;
}

.custom-multi-select {
	.ant-select-selection-overflow {
		flex-wrap: nowrap;
		overflow: hidden;
		align-items: center;
	}

	.ant-select-selection-overflow-item-rest {
		min-width: 60px;
		flex-shrink: 0;
	}

	.ant-select-selection-overflow-item:first-child {
		max-width: calc(100% - 90px);
		flex-shrink: 0;

		span {
			max-width: 100%;
			display: inline-block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			vertical-align: bottom;
			margin: 0 8px 0 0;
		}
	}

	.ant-select-selection-overflow-item:not(:first-child) {
		input:focus {
			min-width: 100px;
			background: #fafafa !important;
			box-shadow: inset 0 1px 3px #ddd;
			border-radius: 5px;
		}
	}
}

@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;