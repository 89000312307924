.sidebar-main {
    box-shadow: 0 3px 6px #00000029;
    .custom-scroll {
        overflow-y: auto;
        overflow-x: hidden;
    }
    .sub-menu-main {
        position: relative;
    }
    .ant-menu-submenu-open.sub-menu-main {
        background: #f2f2f2;
    }
}

.sidebar-main .sub-menu-main .ant-menu-submenu-title,
.sidebar-main .ant-menu-item {
    display: flex;
    align-items: center;
}

.ant-menu-submenu-open.sub-menu-main:after {
    content: "";
    position: absolute;
    left: 0;
    width: 3px;
    height: 44px;
    top: 0;
    background: #4689FF;
}

.ant-menu-item-selected {
    background: unset !important;
}

.collapse-trigger {
    background-color: #efefef;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
}

.collapse-trigger span {
    transition: color 0.3s ease-out;
}

.collapse-trigger span:hover {
    color: #4689FF;
}

.ant-menu-submenu-title:hover .sidebar-icon {
    color: #4689FF;
}

.sidebar-icon {
    font-size: 24px !important;
    color: #999999;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-open .sidebar-icon,
.ant-menu-submenu-selected .sidebar-icon {
    color: #4689FF;
}

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
}

.ant-menu-inline .ant-menu-submenu-title {
    padding-left: 12px !important;
}
.ant-menu-inline .ant-menu-item-selected::after {
    left: 15%;
    right: unset;
    border-right: 1px solid #4689FF !important;
    transition: all 0.3s ease-out;
}

.ant-menu-inline .ant-menu-item::after {
    left: 15%;
    right: unset;
    border-right: 1px solid #cccccc;
    opacity: 1;
    transform: scaleY(1);
    transition: all 0.3s ease-out;
}

.ant-menu-item:first-child {
    margin-top: 16px !important;
}

.ant-menu-item:last-child {
    margin-bottom: 16px !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    pointer-events: none;
}

.icon-1:before {
    content: "\e901";
}

.icon-2:before {
    content: "\e909";
}

.icon-5:before {
    content: "\e90a";
}

.icon-6:before {
    content: "\e906";
}

.icon-9:before {
    content: "\e914";
}

.icon-12:before {
    content: "\e917";
}

.icon-15:before {
    content: "\e900";
}
@height-base: 36px;@primary-color: #4689FF;@link-color: #4689FF;@border-radius-base: 4px;@text-color: #4D4D4D;@label-required-color: #4D4D4D;@label-color: #4D4D4D;@table-header-bg: #E6E6E6;@table-footer-bg: #F5F5F5;@select-multiple-item-height-lg: 36px;@select-dropdown-height: 36px;@menu-item-height: 32px;@table-row-hover-bg: #E5F5F7;@table-expanded-row-bg: #fff;