/* Fonts */

@font-face {
	font-family: "RobotoCondensed";
	src: url("../assets/fonts/RobotoCondensed/RobotoCondensed.eot");
	/* IE9 Compat Modes */
	src: url("../assets/fonts/RobotoCondensed/RobotoCondensed.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/RobotoCondensed/RobotoCondensed.woff") format("woff"),
		url("../assets/fonts/RobotoCondensed/RobotoCondensed.ttf") format("truetype"),
		url("../assets/fonts/RobotoCondensed/RobotoCondensed.svg#svgRoboto") format("svg");
	/* Legacy iOS */
}

@font-face {
	font-family: "RobotoRegular";
	src: url("../assets/fonts/RobotoRegular/RobotoRegular.eot");
	/* IE9 Compat Modes */
	src: url("../assets/fonts/RobotoRegular/RobotoRegular.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/RobotoRegular/RobotoRegular.woff") format("woff"),
		url("../assets/fonts/RobotoRegular/RobotoRegular.ttf") format("truetype"),
		url("../assets/fonts/RobotoRegular/RobotoRegular.svg#svgRobotoRegular") format("svg");
	/* Legacy iOS */
}

@font-face {
	font-family: "RobotoBold";
	src: url("../assets/fonts/RobotoBold/RobotoBold.eot");
	/* IE9 Compat Modes */
	src: url("../assets/fonts/RobotoBold/RobotoBold.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/RobotoBold/RobotoBold.woff") format("woff"),
		url("../assets/fonts/RobotoBold/RobotoBold.ttf") format("truetype"),
		url("../assets/fonts/RobotoBold/RobotoBold.svg#svgRobotoBold") format("svg");
	/* Legacy iOS */
}
@font-face {
	font-family: "RubikMedium";
	src: url("../assets/fonts/RubikMedium/RubikMedium.eot");
	/* IE9 Compat Modes */
	src: url("../assets/fonts/RubikMedium/RubikMedium.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/RubikMedium/RubikMedium.woff") format("woff"),
		url("../assets/fonts/RubikMedium/RubikMedium.ttf") format("truetype"),
		url("../assets/fonts/RubikMedium/RubikMedium.svg#svgRubikMedium") format("svg");
	/* Legacy iOS */
}
@font-face {
	font-family: "RubikRegular";
	src: url("../assets/fonts/RubikRegular/RubikRegular.eot");
	/* IE9 Compat Modes */
	src: url("../assets/fonts/RubikRegular/RubikRegular.eot?#iefix") format("embedded-opentype"),
		url("../assets/fonts/RubikRegular/RubikRegular.woff") format("woff"),
		url("../assets/fonts/RubikRegular/RubikRegular.ttf") format("truetype"),
		url("../assets/fonts/RubikRegular/RubikRegular.svg#svgRubikRegular") format("svg");
	/* Legacy iOS */
}

/* Fonts */
